import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginForm from './LoginForm';
import { UncontrolledAlert } from 'reactstrap';
import { createSession, clearErrors } from 'redux/ducks/Session';
import { capitalize, get } from 'lodash';
import { Link } from 'react-router-dom';

class LoginScene extends Component {
  state = { email: '' };
  componentDidMount() {
    this.props.clearErrors();
  }

  onSubmit = ({ email, password, rememberMe }) => {
    this.props.login({
      email: email,
      password: password,
      rememberMe: rememberMe,
      role: this.props.role,
      history: this.props.history,
    });
  };

  onChooseTitle = () => {
    if (!this.props.role)
      return (
        <div className="v2-title gotham-bold-font">
          <p className="v2-title-p">Log In</p>
        </div>
      );
    return (
      <div className="v2-title gotham-bold-font">
        <p className="v2-title-p">{capitalize(this.props.role)} Portal Log In</p>
      </div>
    );
  };

  setEmail = (email) => {
    this.setState({ email });
  };

  renderErrors = () => {
    const { error } = this.props;
    const { email } = this.state;
    const status = get(error, 'response.status');
    const errors = get(error, 'response.data.errors');

    if (Array.isArray(errors) && errors.includes('unconfirmed')) {
      return (
        <UncontrolledAlert color="danger" className="alert-margin">
          You need to verify your email before accessing your account. Didn’t receive the
          email?&nbsp;
          <Link to={{ pathname: '/v2/resend-confirmation', state: { email } }}>Click here</Link>.
        </UncontrolledAlert>
      );
    }

    if (![401, 404, 403].includes(status)) return null;

    return (
      <UncontrolledAlert color="danger" className="alert-margin">
        {error.message}
      </UncontrolledAlert>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="v2LoginScene gotham-regular-font">
          {this.onChooseTitle()}
          {this.renderErrors()}
          <LoginForm
            {...this.props}
            onSubmit={this.onSubmit}
            role={this.props.role}
            from="login"
            setConfirmationEmail={this.setEmail}
          />
        </div>
        <a className="atSignUpHyperlink" href={`${process.env.REACT_APP_DEEPLINK_URL}/sign-up`}>
          Are you an athletic trainer? Click here.
        </a>
      </React.Fragment>
    );
  }
}

const mapStateToProp = (state, ownProps) => {
  const { session } = state;
  const { error, authenticated, currentUser, sessionExpired } = session;

  if (!error && session && authenticated) {
    if (currentUser.role === 'Parent') {
      ownProps.history.push('/parent/dashboard');
    } else if (currentUser.role === 'Patient') {
      ownProps.history.push('/patient/dashboard');
    } else {
      ownProps.history.push('/dashboard');
    }
  }

  return {
    error,
    sessionExpired,
  };
};

const mapDispatchToProps = (dispatch) => ({
  login: (credentials) => dispatch(createSession(credentials)),
  clearErrors: () => dispatch(clearErrors()),
});

export default connect(mapStateToProp, mapDispatchToProps)(LoginScene);
